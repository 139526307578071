import type { Device } from '@/types/app';
import type { LabradorComponent } from '@/types/component';
import type { Content } from '@/types/content';

import { ArticleInTextTeaser } from '@/components/ArticleInTextTeaser';
import { ArticleTeaser } from '@/components/ArticleTeaser';
import { Link } from '@/components/Link';
import { useAppState } from '@/hooks/useAppState';
import { getUrl } from '@/utils/getUrl';
import { slugify } from '@/utils/slugify';
import { stripMultiLineHtml } from '@/utils/stripHtml';
import { findFirstOfType } from 'lib/labrador/content-operations/findBy';
import { isUndefined } from 'typesafe-utils';

const getImageDimensions = (url: URL) => {
  const searchParams = new URLSearchParams(url.search);
  const width = Number(searchParams.get('width'));
  const height = Number(searchParams.get('height'));
  const scale = width / 980;

  const resolvedWidth = width && Math.round(width / scale);
  const resolvedHeight = height && Math.round(height / scale);

  return { width: resolvedWidth, height: resolvedHeight };
};

const getImageUrl = (descendants: Content[], device: Device) => {
  const image = findFirstOfType(descendants, 'labradorImage');
  const { imageUrl } = image?.data ?? {};

  const url = getUrl(imageUrl?.[device] ?? imageUrl?.desktop ?? imageUrl?.mobile);

  if (!url) return;

  const dimensions = getImageDimensions(url);

  if (dimensions.width && dimensions.height) {
    url.searchParams.set('width', String(dimensions.width));
    url.searchParams.set('height', String(dimensions.height));
  }

  return url.toString();
};

const getImageOptions = (imageUrl?: string, teaserIndex?: number) => {
  const url = getUrl(imageUrl);

  if (!url) return { priority: false, style: {} };

  const { width, height } = getImageDimensions(url);
  const aspectRatio = width && height ? `${width}/${height}` : '2/1';
  const priority = isUndefined(teaserIndex) ? false : teaserIndex < 4;

  return { priority, style: { aspectRatio } };
};

export const HantLabradorArticleTeaser: LabradorComponent = ({ type, data, meta, descendants, ...props }) => {
  const [{ device }] = useAppState();

  const { prominentTag, badge, title, publishedUrl, kicker, category } = data ?? {};
  const { nativeAdLabel, teaserIndex, path } = meta ?? {};

  const isInText = path?.includes?.('bodytext');

  const mediaSrc = getImageUrl(descendants, device);
  const media = {
    src: mediaSrc,
    options: getImageOptions(mediaSrc, teaserIndex),
  };

  const nativeAdHref = nativeAdLabel ? `etikett/${slugify(nativeAdLabel, { replacement: ' ' })}` : undefined;
  const nativeAdTicker = nativeAdHref ? (
    <Link
      href={nativeAdHref}
      content={nativeAdLabel}
      options={{
        className: 'text-headline-xs flex h-full grow items-center justify-center bg-black normal-case text-white',
      }}
    />
  ) : undefined;

  const prominentTagHref = prominentTag ? `etikett/${prominentTag}` : undefined;
  const categoryHref = slugify(category) === 'noje' ? '/' : slugify(category);

  const Component = isInText ? ArticleInTextTeaser : ArticleTeaser;

  return (
    <Component
      kicker={kicker}
      link={{
        teaser: { href: publishedUrl },
        tag: { href: nativeAdHref || prominentTagHref || categoryHref || undefined },
        ticker: nativeAdTicker ? {} : undefined,
      }}
      image={media}
      tag={nativeAdLabel || prominentTag || category || undefined}
      ticker={nativeAdTicker || (badge && { fragment: <span>{badge}</span> }) || undefined}
      title={stripMultiLineHtml(title)}
      data-index={teaserIndex}
      {...props}
    />
  );
};
